
import {  useContext } from "react";

import { Button } from 'primereact/button';
import DappContext from "../../context";

import './Header.css';
import logo from './siblings_logo.png';

export default function Header(){

    const {account, isLoading, isAuthenticated, authenticate, logout} = useContext(DappContext);
    console.log("rerender")

    return(
        
        <header className="header">
            <div className="logo"><img src={logo} alt="Logo" /></div>
            
            {isAuthenticated?(
                // AUTHENTICATED
                <>
                <div className="account">
                    <label className="wallet-address"></label>
                    <Button label={account.substring(0, 5) + "...." + account.substring(account.length - 4)} style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "157px"}} className="p-button-info p-button-text" onClick={logout} />
                </div>
                </>
                ):( 
                <>
                <div className="account">
                    <label className="wallet-address"></label>
                    <Button label={isLoading? "loading": "Connect Wallet"} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={() =>{authenticate()}} iconPos="right" loading={isLoading} />
                </div>
                </>
                )
            }
        </header>
        
    )
}
//{account.substring(0, 5) + "...." + account.substring(account.length - 5)}