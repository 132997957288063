
import {useState, useRef, useEffect} from 'react'

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';       

import "primeicons/primeicons.css";   
import './Popup.css'

import { checkIfActive, burn , activate} from '../../utils/interactions';

export const Popup = ({selected}) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [burnButton, setBurnButton] = useState("Burn")
    const [dialogState, setDialogState] = useState(0)
    const [burnDisabled, setburnDisabled] = useState(true)

    const [txHash, setTxHash] = useState("")
    const [isLoading, setisLoading] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const [success, setSuccess] = useState(false)

    const [isError, setisError] = useState(false)
    const [error, seterror] = useState("")

    
    useEffect(() => {
        if(txHash !== ""){
            console.log("txHash changed")
            setisLoading(false)
        }
        
    }, [txHash])

    useEffect(() => {
        if(selected > 0){
            setburnDisabled(false);
        }else{
            setburnDisabled(true);
        }
        
    }, [selected])
    
    const burnForRewards = async() =>{
        setDisplayBasic(true)
        if(isError){
            setDialogState(3);
            setBurnButton("Close");
        }
    }

    const apply = async () =>{
        if(dialogState === 0){
            setisLoading(true);
            setSuccess(true)
            setDialogState(1);

            // const tx = await activate();
            // setTxHash(tx.hash);
            // const receipt = await tx.wait();
            
            burn().then(async (tx)=>{
                setTxHash(tx.hash);
                const receipt = await tx.wait();
                console.log(receipt)
                setSuccess(false);

                setConfirmed(true);
                setBurnButton("View here");
                setDialogState(2);
            }).catch((e)=>{
                console.log(e.error.message)
                setisError(true);
                seterror(e.error.message)
                setisLoading(false)
                setSuccess(false);
                // setDisplayBasic(false);
                setConfirmed(false);
                setDialogState(3);
                setBurnButton("Close");
                setTxHash("");
            })
            
        } else if(dialogState === 2){
            
            setDisplayBasic(false);
            setConfirmed(false);
            setDialogState(0);
            setBurnButton("Burn");
            setTxHash("");
        }else if(dialogState === 3){
            setDisplayBasic(false);
            setDialogState(0);
            setBurnButton("Burn");
        }
       
        // contract interaction
    }

    const renderFooter = () => {
        return (
            <div>
                {dialogState !== 1 && <Button label={burnButton} style={{color:"#fff", background: "#3333ff", fontWieght:"400"}} className="p-button-raised p-button-info p-button-text burn" onClick={() => apply()} autoFocus disabled={success}/>}
            </div>
        );
    }

    const items = [
        {label: ''},
        {label: ''},
        {label: ''}
    ];

    return (
        <>
        <Dialog header="" visible={displayBasic} style={{ width: '30vw', height:"430px", backgroundColor: "#F6F6F6"}} footer={renderFooter()} onHide={() => setDisplayBasic(false)} breakpoints={{'960px': '75vw', '640px': '100vw'}}>
            {!isError 
            ?<>
                <div className='steps'>
                    <div className='step-circle circle-active'>1</div>
                    <div className={dialogState !== 0 ? "steps-line line-active" : "steps-line"}></div>
                    <div className={dialogState !== 0 ? "step-circle circle-active" : "step-circle" }>2</div>
                    <div className={dialogState === 2 ? "steps-line line-active" : "steps-line"}></div>
                    <div className={dialogState === 2 ? "step-circle circle-active" : "step-circle" }>3</div>
                </div>
                {isLoading
                ?<div className='load'>
                    <br/>
                    <ProgressSpinner />
                    <br/>
                    <br/>
                    <div className='message'>Confirm transaction in your wallet...</div>
                </div>
                :txHash !== "" 
                ? !confirmed 
                ?<div className='message'>
                    Your transaction has been submitted and is awaiting confirmation. You can view your transaction <a href={"https://rinkeby.etherscan.io/tx/" + txHash} target="_blank" rel="noopener noreferrer">here</a>
                    <br/>
                    <br/>
                    <ProgressSpinner />
                    <br/>
                </div> 
                : <div className='message'>
                    <div className='success'>SUCCESS</div>
                    <br/>
                    <div>Click the button below to view your rewards</div>
                </div> 
                : <div className='message'>You're about to burn {selected} Siblings, click burn to continue</div>
                }
            </>
            : <div className='message'>
                <div className='error'>{error}</div>
                </div>
            }
            
        </Dialog>
        <Button disabled= {burnDisabled} label="Burn for rewards" style={{color:"#fff", background: "#3333ff"}} className="p-button-raised p-button-info p-button-text burn"  onClick={() => burnForRewards()}/>
        </>
    )
}
