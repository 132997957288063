
import { ethers } from "ethers"
import {abi} from './ABI' 


const burnAddress = "0x2D44551601c5bA6e52a712A655b5D9d8f4033A4F"
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();
const burnContract = new ethers.Contract(burnAddress, abi, signer);


const checkIfActive = () =>{
    const tx = burnContract.active().then(function(result){
        console.log(result);
    });
}

const burn = async () =>{
    const tx = await burnContract.burnAvatar([3]);
    return tx;
}

const activate = async () =>{
    const tx = await burnContract.toggleBurning();
    console.log(`Transaction hash: ${tx.hash}`);
    return tx;
}
export {checkIfActive, burn, activate}