import {useContext, useState} from 'react'
import DappContext from '../../context';

import { Editor } from '../Editor/Editor';

import { Button } from 'primereact/button';

import './Info.css'

const Info = () => {
    const {db_data, db_load} = useContext(DappContext);

    

    return (
        
        <div className='info-container'>
            
            <div className='info'>
                <div className='title'>How the burn will work?</div>
                <div className='info-section'>
                    <div className='circle'>1</div>
                    <div className='text'>
                        {!db_load ? db_data.burnInfo_f1.text : "loading"}
                        {/* <Editor obj = "burnInfo_f1"/> */}
                    </div>
                </div>
                <div className='info-section'>
                    <div className='circle'>2</div>
                    <div className='text'>
                        {!db_load ? db_data.burnInfo_f2.text : "loading"}
                        {/* <Editor obj = "burnInfo_f2"/> */}
                    </div>
                </div>
                <div className='info-section'>
                    <div className='circle'>3</div>
                    <div className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        Vivamus id mauris id nunc commodo tincidunt sit amet eget ex. 
                        In pretium risus eu lacus mollis.
                    </div>
                </div>
                
                <div className='button-container'>
                    <Button label="Our medium article" className="button"  />
                </div>
            </div>
            
        </div>
    )
}

export default Info