import {useEffect, useReducer, useState} from 'react';
import { ethers } from "ethers";




function loginReducer(state, action) {
    switch (action.type) {
      case 'login': {
        return {
          ...state,
          account: action.account,
          tokens: action.tokens,
          isLoading: false,
          isAuthenticated: true,
          isError: false,
        };
      }
      case 'logout': {
        return {
          ...state,
          account: '',
          tokens: '',
          isAuthenticated: false,
          isError: false,
        };
      }
      case 'reset': {
        return action.payload;
      }
      case 'error': {
        return {
          ...state,
          isError: action.isError,
          
        };
      }
      case 'loading': {
        return {
          ...state,
          isLoading: true,
          
        };
      }
      default: {
        return state;
      }
    }
  }
  
const initialState = {
    account: '',
    isLoading: false,
    isAuthenticated: false,
    network: '', 
    isError: false,
    tokens: ""
}

export default function Account() {
  
    const [state, dispatch] = useReducer(loginReducer, initialState)

    // useEffect(()=>{
    //   const raw = localStorage.getItem('data');
    //   dispatch({type: 'reset', payload: JSON.parse(raw)});
    // }, [])
  
    // useEffect(()=>{
    //   localStorage.setItem('data', JSON.stringify(state));
    // },[state])

    useEffect(() => {
        if(window.ethereum) {
            window.ethereum.on('chainChanged', () => {
            logout();
            })
            window.ethereum.on('accountsChanged', () => {
            logout();
            })
        return()=>{
            window.ethereum.on('chainChanged', () => {})
            window.ethereum.on('accountsChanged', () => {})
        }
    }})
    
  
    const authenticate = async () => {
        dispatch({type: 'loading'});
        console.log("authenticating");
        if(window.ethereum){
            const networkId = await window.ethereum.request({ method: 'eth_chainId' });
            dispatch({type: 'error', isError: false });
            // if(networkId !=="0x1"){
            //     dispatch({type: 'error', isError: true });
            // //   alert("Please switch to mainnet ethereum network and try again.");
            //     return;
            // }
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const accounts = await provider.send("eth_requestAccounts", []);

            const tokens = await getSiblings(accounts[0])

            dispatch({type: 'login', account: accounts[0], tokens:tokens});
        }
    }
    const logout = () => {
      dispatch({type: 'logout', });
    }

    const getMetadata = async (token_id) =>{
        try {
            const response = await fetch('https://api.opensea.io/api/v1/asset/0x49aC61f2202f6A2f108D59E77535337Ea41F6540/'+ token_id +'/?include_orders=false',{
            method: "GET",
            headers: {"accept": "application/json",
                    }
            });

            if (!response.ok) {
                throw new Error(
                `This is an HTTP error: The status is ${response.status}`
                );
            }
            let actualData = await response.json();
            const img_url = actualData.image_url;
            return img_url;
        } catch(err) {
            console.log("error")
        }
    }
    const parseTokens = async (data) => {
        const siblings = [];
        for(let x = 0; x< data.length;x++){
            const sibling = {}
            const token_id = data[x].token_id
            sibling["token_id"] = token_id;
            sibling["image"] = await getMetadata(token_id);
            siblings.push(sibling)
        }
        console.log(siblings)
        return siblings
    }

    const getSiblings = async (address) => {
        try {
            const siblingsAddres = '0x49aC61f2202f6A2f108D59E77535337Ea41F6540'
            const test = '0xC651373fFbA23f7116370935bF67703A69E31d74'
            const response = await fetch(`https://deep-index.moralis.io/api/v2/${address}/nft/${test}?chain=rinkeby&format=decimal`,{
            method: "GET",
            headers: {"accept": "application/json",
                    "X-API-Key":  "rqWbhqkSr5He5waOYl4VIa5Q73cfmT3ic2Z02KXEG4AllqXAw1ksv8PjLLRNRCzA"  }
            });

            if (!response.ok) {
                throw new Error(
                `This is an HTTP error: The status is ${response.status}`
                );
            }
            let actualData = await response.json();
            const parsed = await parseTokens(actualData.result);
            return parsed;
            // console.log(actualData.result)
        } catch(err) {
            console.log("error")
        }
    }

    return {state, authenticate, logout};
}