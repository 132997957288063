import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { doc, setDoc, getDoc } from "firebase/firestore"; 


const firebaseConfig = {
    apiKey: "AIzaSyCxVKY-pk0Y766pDuS2To3IoGoxx-nn00c",
    authDomain: "nftsiblings-913b4.firebaseapp.com",
    projectId: "nftsiblings-913b4",
    storageBucket: "nftsiblings-913b4.appspot.com",
    messagingSenderId: "982155190695",
    appId: "1:982155190695:web:f5ff7eb632fd7dc6291682"
  };

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();

const addDocument = async (col, docName, data) => {
  await setDoc(doc(db, col, docName), data);
}

const getDocument = async (col, docName) => {
  const docRef = doc(db, col, docName);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return false;
  }
}


export { auth, db, addDocument, getDocument};