
import {  useContext, useState, useEffect} from "react";


import DappContext from "../../context";

import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';

import './Burn.css'
import 'primeflex/primeflex.css'

import { Popup } from "./Popup";

const Burn = () => {
    const [siblings, setSiblings] = useState([]);
    const [selected, setSelected] = useState([]);
    const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(0);

    const [showDialog, setShowDialog] = useState(false);

    const {tokens} = useContext(DappContext);


    useEffect(() => {
        setSiblings(tokens);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const selectTokens = (sib, e) => {
        // setSelected({...selected, [siblings.token_id]:e.value })
        setSelected({...selected, [sib]:e.value })
        setTotalSelectedCheckboxes(document.querySelectorAll('input[type=checkbox]:checked').length);
    }

    const responsiveOptions = [
        
        {
            breakpoint: '1390px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const productTemplate = (siblings) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="mb-3">
                        <img src={siblings.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={siblings.token_id} className="product-image" />
                    </div>
                    <div className="footer">
                        <h4 className="">{"NFT Siblings #" + siblings.token_id}</h4>
                        <input type="checkbox"  onChange={(e) => selectTokens(siblings.token_id, e)} checked={selected[siblings.token_id]}></input>
                        {/* <input type="checkbox"  onChange={(e) => setSelected({...selected, [siblings.token_id]:e.value })} checked={selected[siblings.token_id]}></input> */}
                        {/* <InputSwitch style={{padding:"0px 20px"}}checked={selected[siblings.token_id]} onChange={(e) => setSelected({...selected, [siblings.token_id]:e.value ,})} /> */}
                    </div>
                </div>
            </div>
        );
    }
    
    return (
        <>
            {/* <Button label="test" onClick={test()}/> */}
            

            <div className="burn-container">
                
                
                <div className="carousel-demo">
                    <div className="card">
                        <Carousel 
                            header={<div className="carousel-header" >
                                        <div> YOUR SIBLINGS </div>
                                        <div className="selected">{totalSelectedCheckboxes} selected</div>
                                    </div>}
                            value={siblings} 
                            numVisible={5} 
                            numScroll={5} 
                            itemTemplate={productTemplate}
                            circular = {false}
                            orientation = "horizontal"
                            responsiveOptions={responsiveOptions}
                            containerClassName  = "cont"
                        />
                    </div>
                    <div className="burn-button">
                        <Popup selected={totalSelectedCheckboxes}/>
                    </div>
                </div>
                
                
                
            </div>

        </>
    )
}

export default Burn