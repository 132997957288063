import { useEffect, useState, useRef, useReducer} from "react";
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';

import {db, getDocument} from "./utils/firebase";
import { collection, getDocs} from "firebase/firestore";

import './App.css';
import "primereact/resources/themes/md-dark-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";    
import { Toast } from 'primereact/toast'     
import { ProgressSpinner } from 'primereact/progressspinner';        


import DappContext from "./context";
import Account from "./utils/account";
import Header from "./components/Header/Header";
import Burn from "./components/Burn/Burn";
import Mainpage from "./components/Mainpage/Mainpage";
import Info from "./components/Info/Info";




function App() {

  
  const [db_data, setdb_data] = useState("")
  const [db_load, setdb_load] = useState(true)

  const db_init = async () => {
    const querySnapshot = await getDocs(collection(db, "data"))
    const items = {}
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      items[doc.id] =  doc.data()
    });
    setdb_data(items);
    setdb_load(false);
  }
  

  // const getDbData = () =>{
  //   db_test.onSnapshot((query)=>{
  //     const items = []
  //     query.forEach(element => {
  //       items.push(element.data())
  //     });
  //     setdb_data(items);
  //     setdb_load(false);
  //   })
  // }
  useEffect(async () => {
    db_init();
  }, [])
  

  const toast = useRef();

  const {state, authenticate, logout} = Account(toast);
  const {account, tokens, isLoading, isAuthenticated, network, isError} = state;

  if(isError){
    toast.current.show({severity: 'warn', summary: 'Please switch to mainnet ethereum network and try again.', detail: ''});
  }

  return (
    <>
    <Toast ref={toast}></Toast>
      <DappContext.Provider  value={{account, 
                                      tokens, 
                                      isLoading, 
                                      isAuthenticated, 
                                      authenticate, 
                                      logout, 
                                      db_data, 
                                      db_load}}>
        <div className="app">
          <Header />
          <div className="container">
            <Info />
            {/* <Burn /> */}
            {isAuthenticated ? <Burn /> : <div className="notConnected">            
                                            {/* <div className="not-connected-msg">{ isAuthenticated ? (isLoading ? "Loading Siblings": "Connecting") :"Connect your wallet to get started with burning"}</div> */}
                                            <div className="not-connected-msg">{ isLoading ? "Loading Siblings" :"Connect your wallet to get started with burning"}</div>

                                            <br />
                                            <br />
                                            {isLoading && <ProgressSpinner/>}
                                          </div>}
            
          </div>
          {/* <Mainpage /> */}
        </div>
          

      </DappContext.Provider>
    </>
  );
}


export default App;
